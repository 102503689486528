<template>
  <div class="goods">
    <van-swipe class="goods-swipe" :autoplay="3000">
      <van-swipe-item v-for="item in goods.goodsImageList" :key="item.id">
        <img :src="item.goodsImgUrl" >
      </van-swipe-item>
    </van-swipe>

    <van-cell-group>
      <van-cell>
        <div class="goods-title">{{ goods.goodsName }}</div>
      </van-cell>
      <van-cell class="goods-express">
        <van-col span="8" style="color: #F14F35">现价：{{ goods.goodsRelPrice }}</van-col>
        <van-col span="16" style="color: #A7A7A7">原价：{{ goods.goodsOrgPrice }}</van-col>
      </van-cell>
    </van-cell-group>
    <div class="bigimg" v-html="goods.goodsDetail"></div>
    <van-goods-action>
      <van-goods-action-button style="border-radius:0px;margin: 0px;height: 100%;"  type="danger">
        我要进货
      </van-goods-action-button>
    </van-goods-action>
  </div>
</template>

<script>
import {
  Tag,
  Col,
  Icon,
  Cell,
  CellGroup,
  Swipe,
  Toast,
  SwipeItem,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton
} from 'vant';
import { findGoods } from "@/api/redpack";
export default {
  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionIcon.name]: GoodsActionIcon,
    [GoodsActionButton.name]: GoodsActionButton
  },

  data() {
    return {
      goods: {
        goodsName: '',
        goodsOrgPrice: 0,
        goodsRelPrice: 0,
        goodsImageList: [],
        goodsDetail:''
      }
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(){
      var id = this.$route.query.id;
      findGoods(id).then(response => {
        if (response && response.code == 200){
          this.goods = response.data;
          console.log('data',response);
        }
      })
    }
  }
};
</script>

<style lang="less">
  .van-swipe-item > img{
    width: 100%;
  }
  .bigimg > p > img{
    width: 100%;
  }
  .goods {
    padding-bottom: 50px;


    &-title {
      font-size: 16px;
    }

    &-price {
      color: #f44;
    }

    &-express {
      color: #999;
      font-size: 12px;
      padding: 5px 15px;
    }

    &-cell-group {
      margin: 15px 0;

      .van-cell__value {
        color: #999;
      }
    }

    &-tag {
      margin-left: 5px;
    }
}
</style>
